<template>
    <div class="login-wrap">
        <div class="ms-login" :class="isSelect">
            <div class="ms-title">{{ titleText }}</div>
            <el-form
                :model="param"
                :rules="rules"
                ref="login"
                label-width="0px"
                class="ms-content"
            >
                <el-form-item prop="name">
                    <el-input v-model="param.name" placeholder="name">
                        <template #prepend>
                            <el-button icon="el-icon-user"></el-button>
                        </template>
                    </el-input>
                </el-form-item>
                <el-form-item prop="passwd">
                    <el-input
                        type="password"
                        placeholder="passwd"
                        v-model="param.passwd"
                        @keyup.enter="submitForm()"
                    >
                        <template #prepend>
                            <el-button icon="el-icon-lock"></el-button>
                        </template>
                    </el-input>
                </el-form-item>
                <div class="login-btn">
                    <el-button type="primary" @click="submitForm()"
                        >登录</el-button>
                </div>
                <!-- <p class="login-tips">Tips : 用户名和密码随便填。</p> -->
            </el-form>
            <div class="roles-box">
                <div class="col-role" 
                     v-for="(item, index) in roleList" 
                     :key="index"
                     @click="login(item.role_id)">
                    <div class="role">
                        {{ item.role_name }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { fetchData } from "../api/api";
export default {
    data() {
        return {
            param: {
                name: "",
                passwd: "",
            },
            rules: {
                name: [
                    {
                        required: true,
                        message: "请输入用户名",
                        trigger: "blur",
                    },
                ],
                passwd: [
                    { required: true, message: "请输入密码", trigger: "blur" },
                ],
            },
            roleList: [],
            isLogin: false,
            num: 6
        };
    },
    created() {
        this.$store.commit("clearTags");
    },
    computed: {
        isSelect() {
            return this.isLogin ? 'select-role-box' : '';
        },
        titleText() {
            return this.isLogin ? "请选择登录的角色" : "后台管理系统";
        }
    },
    methods: {
        submitForm() {
            this.$refs.login.validate((valid) => {
                if (valid) {
                    fetchData(this.param, "/admin/v1/login", "GET").then(
                        (res) => {
                            localStorage.setItem(
                                "adminInfo",
                                JSON.stringify({
                                    token: res.data.token,
                                    id: res.data.id,
                                    name: res.data.name,
                                })
                            );
// localStorage.setItem(
//                         "ms_username", res.data.name,
//                     );
                            fetchData({super_user_id: res.data.id}, "/admin/v1/listPersonalRoles", "GET").then(
                                (res) => {
                                    this.roleList = res.data;
                                    if (res.data.length === 0) {
                                        this.$message.error("请分配角色后登录");
                                    } else if (res.data.length === 1) {
                                        this.login(res.data[0].role_id);
                                    } else {
                                        this.isLogin = !this.isLogin;
                                    }
                                }
                            );
                        }
                    );
                } else {
                    this.$message.error("请输入账号和密码");
                    return false;
                }
            });
        },
        login (roleId) {
            fetchData(
                {name: this.param.name, passwd: this.param.passwd, role_id: roleId}, 
                "/admin/v1/login", 
                "GET"
            ).then(
                (res) => {
                    this.$message.success("登录成功");
                    localStorage.setItem(
                        "adminInfo",
                        JSON.stringify({
                            token: res.data.token,
                            id: res.data.id,
                            name: res.data.name,
                            role_id: roleId,
                        })
                    );
                    localStorage.setItem(
                        "ms_username", res.data.name,
                    );
                    setTimeout(() => {
                        this.$router.push("/");
                    },1000);
                }
            );
        }
    },
};
</script>

<style scoped lang="scss">
.login-wrap {
    position: relative;
    width: 100%;
    height: 100%;
    background-image: url(../assets/img/login-bg.jpg);
    background-size: 100%;
}
.ms-title {
    width: 100%;
    line-height: 50px;
    text-align: center;
    font-size: 20px;
    color: #fff;
    border-bottom: 1px solid #ddd;
}
.ms-login {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 18%;
    margin: -190px 0 0 -175px;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.3);
    overflow: hidden;
    transition: left 0.4s, width 0.4s;
    .roles-box {
        display: none;
    }
}
.ms-content {
    padding: 30px 30px;
}
.login-btn {
    text-align: center;
}
.login-btn button {
    width: 100%;
    height: 36px;
    margin-bottom: 10px;
}
.login-tips {
    font-size: 12px;
    line-height: 30px;
    color: #fff;
}
.select-role-box {
    width: 50%;
    // width: 600px;
    left: 35%;
    .roles-box {
        display: block;
        padding: 10px;
        width: 100%;
        &::after {
            content: "";
            display: block;
            clear: both;
        }
        .col-role {
            width: 23%;
            float: left;
            margin-right: 1.9%;
        }
        .role {
            text-align: center;
            font-size: 16px;
            background-color: #fff;
            margin-top: 1rem;
            border-radius: 5px;
            padding: 2rem 0;
            color: #606266;
            cursor: pointer;
            &:hover {
                outline: 1px solid #666;
            }
        }
    }
    .ms-content {
        display: none;
    }
}
</style>